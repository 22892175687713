import React, { useEffect } from 'react';

import { Trans } from '@lingui/macro';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';
import { TransparentLabel } from 'components/ui/inputs/Label';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

export const StyledDropdown = styled(Form.Dropdown)`
  &&&& {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;

    ${({ fluid }) => (fluid ? 'width: 100%; flex-grow: 1;' : '')}

    & div.dropdown {
      background: ${svars.colorWhite};
      min-width: ${({ small, compact }) =>
        (small && svars.smallInputMinWidth) ||
        (compact && 0) ||
        svars.inputMinWidth};
      width: 100%;

      & div.text {
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: clip;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
      }
    }

    // & div > div,
    & div > div > div.message {
      min-width: 0;
      line-height: 1.2;

      & .item,
      .item.selected {
        border-radius: ${svars.ctaBorderRadius};
      }
    }

    & .menu {
      border-radius: 0 0 ${svars.ctaBorderRadius} ${svars.ctaBorderRadius};
      ${({ maxheight }) => (maxheight ? `max-height: ${maxheight}` : '')}
    }
    & .ui.upward.selection.dropdown .menu {
      border-radius: ${svars.ctaBorderRadius} ${svars.ctaBorderRadius} 0 0;
      &::-webkit-scrollbar-track {
        background: none;
        margin-top: 1.5rem;
        margin-bottom: 0;
      }
    }
    & div > .dropdown.icon {
      height: 100%;
      display: flex;
      align-items: center;
    }

    & .active,
    .selection.active,
    :hover {
      & .menu {
        &::-webkit-scrollbar-track {
          background: none;
          margin-top: 0;
          margin-bottom: 1.5rem;
        }
      }
    }

    & {
      div.selection.multiple > a.ui.label {
        border: ${svars.accentColor} solid 1px;
        box-shadow: none;
        color: ${svars.accentColor};
        background: ${svars.colorWhite};
      }
    }
  }
`;

StyledDropdown.Item = Form.Dropdown.Item;
StyledDropdown.Menu = Form.Dropdown.Menu;

const formatI18nField = (
  options,
  fromField = 'i18nText',
  toField = 'text',
  doCapitalize = true
) =>
  options.map((option) => {
    const newOption = omit(option, [fromField]);
    if (option[fromField]) newOption[toField] = option[fromField];
    if (doCapitalize)
      newOption[toField] = (
        <Trans render={capitalizedTranslation} id={newOption[toField]} />
      );
    return newOption;
  });

export function I18nDropdown({ options, ...props }) {
  // eslint-disable-next-line no-console
  // console.log('I18nDropdown is displayed, check translation');
  return (
    <StyledDropdown
      options={formatI18nField(options)}
      {...omit(props, ['i18nHash'])}
    />
  );
}

I18nDropdown.propTypes = {
  ...StyledDropdown.propTypes,
};

export function SelectBox({
  disabled,
  help,
  label,
  multiple,
  noResultMessage,
  onChange,
  options,
  placeholder,
  value,
  style,
  withI18n,
  testid,
  ...props
}) {
  useEffect(() => {
    if (!multiple && !value && options && options.length === 1) {
      // If only one item exists and value is empty, select it
      onChange(null, { ...options[0], options });
    }
  }, [options]);
  const DropdownComponent = withI18n ? I18nDropdown : StyledDropdown;
  return (
    <Form.Field inline style={style}>
      {label != null && (
        <TransparentLabel disabled={disabled}>
          <span
            style={{ fontSize: svars.fontSizeBase, paddingRight: '0.7rem' }}
          >
            {label}
          </span>
          {help ? <HelpTooltip size="small" help={help} /> : null}
        </TransparentLabel>
      )}
      <DropdownComponent
        placeholder={placeholder}
        selection
        search
        deburr
        style={{ minWidth: '3rem' }}
        disabled={disabled}
        options={options}
        onChange={onChange}
        value={value}
        multiple={multiple}
        noResultsMessage={noResultMessage}
        data-testid={testid}
        {...props}
      />
    </Form.Field>
  );
}

SelectBox.defaultProps = {
  placeholder: '',
  label: null,
  help: null,
  disabled: false,
  multiple: false,
  noResultMessage: 'Aucun résultat.',
  style: null,
  withI18n: false,
  testid: undefined,
};

SelectBox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  noResultMessage: PropTypes.string,
  style: commonPropTypes.style,
  // Whether the dropdown should use the I18nDropdown component
  withI18n: PropTypes.bool,
  testid: PropTypes.string,
};
