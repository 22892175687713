import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import InTextDropdown from 'components/ui/inputs/InTextDropdown';

import * as svars from 'assets/style/variables';

function AlternativeChartDropdownPortal({ nodeId, disabled, ...props }) {
  const element = document.createElement('span');
  useEffect(() => {
    const mount = document.getElementById(nodeId);
    if (mount) {
      mount.appendChild(element);
      return () => mount.removeChild(element);
    }
    return null;
  }, [element, nodeId]);
  return ReactDOM.createPortal(
    disabled ? (
      <span style={{ marginLeft: svars.spaceNormal }}>...</span>
    ) : (
      <InTextDropdown
        style={{
          minWidth: 0,
          flexGrow: 0,
          display: 'inline',
        }}
        fluid
        {...props}
      />
    ),
    element
  );
}
export default AlternativeChartDropdownPortal;
