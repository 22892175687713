import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

import { HoverableIconButton } from 'components/ui/icon/HoverableIcon';

import * as svars from 'assets/style/variables';

const style = document.createElement('style');
// Commented as it affects other elements in the app - should require a fix
style.innerHTML = `
  .DDLI__placeholder {
    height: 60px;
    width: 100%;
    background: 'blue';
  }
`;
document.head.appendChild(style);

const placeholder = document.createElement('div');
placeholder.className = 'DDLI__placeholder';

class DragAndDropList extends Component {
  static findDraggableTarget = (node) => {
    let selected = node;
    while (selected.className !== 'Draggable') {
      selected = selected.parentNode;
      if (selected == null) break;
    }
    return selected;
  };

  constructor(props) {
    super(props);
    this.dragOver = this.dragOver.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.dragStart = this.dragStart.bind(this);
    document.ondragover = (element) => element.preventDefault();
  }

  dragStart(e) {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.dragged);
  }

  dragEnd() {
    const { elements, onChange } = this.props;
    this.dragged.style.display = 'inline-flex';
    this.dragged.parentNode.removeChild(placeholder);
    const from = Number(this.dragged.dataset.id);
    const to = Number(this.over.dataset.id);
    const sortedElements = [...elements];
    sortedElements.splice(to, 0, sortedElements.splice(from, 1)[0]);
    onChange(sortedElements);
  }

  dragOver(e) {
    e.preventDefault();
    this.dragged.style.display = 'none';
    if (e.target.className === placeholder.className) return;
    this.over = e.target;
    const draggable = DragAndDropList.findDraggableTarget(e.target);
    if (draggable) {
      const from = Number(this.dragged.dataset.id);
      const to = Number(this.over.dataset.id);
      let toInsertBefore = draggable;
      if (!Number.isNaN(to) && from < to) {
        toInsertBefore = draggable.nextSibling;
      }
      draggable.parentNode.insertBefore(placeholder, toInsertBefore);
    }
  }

  render() {
    const { onChange, elements, renderElementSegment } = this.props;
    return (
      <div
        onDragOver={this.dragOver}
        style={{ width: '100%', overflow: 'auto' }}
      >
        {elements.map((element, i) => (
          <React.Fragment key={`ddli-${element.id}-${element.position}`}>
            <div
              style={{ width: '100%', pointerEvents: 'auto' }}
              key={`label-${element.id}`}
              onDragEnd={this.dragEnd}
              onDragStart={this.dragStart}
              onDragEnter={(e) => e.preventDefault()}
              data-id={i}
              className="Draggable"
            >
              <Segment
                style={{
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                  width: '98%',
                  margin: '0 1%',
                  pointerEvents: 'none',
                  alignItems: 'center',
                  background: element.archived
                    ? svars.colorDangerLightest
                    : 'inherit',
                }}
              >
                {onChange ? (
                  <HoverableIconButton
                    name="bars"
                    style={{ cursor: 'move' }}
                    onMouseEnter={(e) => {
                      e.target.parentNode.parentNode.draggable = 'true';
                    }}
                    onMouseLeave={(e) => {
                      e.target.parentNode.parentNode.draggable = null;
                    }}
                  />
                ) : null}
                {renderElementSegment(element)}
              </Segment>
            </div>
            <div
              style={{ height: svars.spaceMedium, pointerEvents: 'auto' }}
              data-id={i}
              key={`div-margin-${element.id}`}
            />
          </React.Fragment>
        ))}
      </div>
    );
  }
}

DragAndDropList.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
          ])
        ),
      ])
    )
  ).isRequired,
  // If not set, drag and drop is deactivated
  onChange: PropTypes.func,
  renderElementSegment: PropTypes.func.isRequired,
};
DragAndDropList.defaultProps = { onChange: null };

export default DragAndDropList;
