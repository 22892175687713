import PropTypes from 'prop-types';
import { Checkbox as BaseCheckBox } from 'semantic-ui-react';
import styled from 'styled-components';

import commonPropTypes from 'utils/commonPropTypes';
import withClickAnalytics from 'utils/withAnalytics';

import { accentColor, fontColorBase } from 'assets/style/variables';

export const LightCheckbox = styled(BaseCheckBox)`
  &&&&&&& {
    & label {
      color: ${fontColorBase} !important;
      font-weight: normal;
    }
  }
`;

export const Checkbox = styled(BaseCheckBox)`
  &&&&&&& {
    & label {
      color: ${fontColorBase} !important;

      &::after {
        color: ${accentColor};
      }
    }
  }
`;

const AnalyticsAwareCheckbox = withClickAnalytics(Checkbox, 'onChange');

AnalyticsAwareCheckbox.propTypes = {
  ...BaseCheckBox.propTypes,
  as: commonPropTypes.component,
  onChange: PropTypes.func,
  gaCategory: PropTypes.string,
  gaAction: PropTypes.string,
  gaLabel: PropTypes.string,
  gaNonInteraction: PropTypes.bool,
};

AnalyticsAwareCheckbox.defaultProps = {
  ...BaseCheckBox.defaultProps,
  as: Checkbox,
  gaCategory: undefined,
  gaAction: undefined,
  gaLabel: undefined,
  gaNonInteraction: false,
};

export { AnalyticsAwareCheckbox };
