import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { setWordingToDefault, setWordingValue } from 'actions/campaign';
import { wordingValueSelectorFactory } from 'selectors/campaign';

import Header from 'components/ui/Header';
import HelpTooltip from 'components/ui/HelpTooltip';
import HoverableIcon from 'components/ui/icon/HoverableIcon';
import { TextInput } from 'components/ui/inputs/TextInput';

import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

function FeedbackAppCustomizationInput({
  inputLabel,
  campaignId,
  page,
  inputKey,
}) {
  const dispatch = useDispatch();
  const wordingValues = useMemoizedFactorySelector(
    wordingValueSelectorFactory,
    campaignId,
    page,
    inputKey
  );
  const onValueChange = useCallback(
    (event) => {
      dispatch(setWordingValue(campaignId, page, inputKey, event.target.value));
    },
    [campaignId, page, inputKey]
  );
  const undoValueChange = useCallback(
    () => dispatch(setWordingToDefault(campaignId, page, inputKey)),
    [campaignId, page, inputKey]
  );
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Header
          content={inputLabel}
          style={{
            marginTop: svars.spaceMedium,
            marginBottom: svars.spaceNormal,
          }}
        />
        <div>
          <HelpTooltip
            position="top right"
            mouseEnterDelay={50}
            mouseLeaveDelay={50}
            style={{}}
            disabled={wordingValues.isDefault}
            help={<span>Reset to published campaign value</span>}
            trigger={
              <span>
                <HoverableIcon
                  name="undo"
                  disabled={wordingValues.isDefault}
                  onClick={undoValueChange}
                />
              </span>
            }
          />
        </div>
      </div>
      <TextInput
        style={{ width: '100%', marginBottom: svars.spaceMedium }}
        value={wordingValues.value}
        onChange={onValueChange}
      />
    </>
  );
}

FeedbackAppCustomizationInput.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  inputKey: PropTypes.string.isRequired,
};

export default FeedbackAppCustomizationInput;
