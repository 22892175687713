import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formElementSelectorFactory } from 'selectors/campaign';
import {
  annotateFeedback,
  annotateLoadingSelector,
} from 'store/monitor/monitorSearchSlice';

import Link from 'components/ui/Link';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import { TextInput } from 'components/ui/inputs/TextInput';

import * as svars from 'assets/style/variables';

import { StyledDropdown } from '../inputs/Dropdown';
import { LabelCell } from '../table/cells/ReactTableCell';

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  width: 100%;
`;
function EditableAnnotationCell({
  formId,
  feedbackId,
  isText,
  value: savedValue,
  color: savedColor,
}) {
  const [editing, setEditing] = useState(false);
  const [formValue, setFormValue] = useState(null);
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const {
    formElement: { placeholder, label, mutable },
    tagSet,
  } = useSelector(
    formElementSelectorFactory(campaignId, 'annotation_form', formId, true)
  );
  const annotateLoading = useSelector(annotateLoadingSelector);
  const onChange = useCallback((e, { value }) => setFormValue(value), []);
  const onSave = useCallback(
    () =>
      dispatch(
        annotateFeedback({
          campaignId,
          feedbackId,
          formId,
          value: formValue,
        })
      ).then(() => setEditing(false)),
    [campaignId, feedbackId, formId, formValue]
  );
  const commonProps = {
    style: { width: '100%' },
    value: formValue,
    onChange,
    placeholder: placeholder || label,
  };
  if (savedValue && savedValue !== '-' && !editing) {
    return (
      <CellContainer>
        <LabelCell value={savedValue} color={savedColor} />
        {mutable ? (
          <AnalyticsAwareHoverableIconButtonWithTooltip
            onClick={() => setEditing(true)}
            style={{ margin: `0 ${svars.spaceMedium}` }}
            help={t`edit-annotation`}
            name="edit"
            gaCategory="Monitoring - search"
            gaAction="Edit feedback annotation"
          />
        ) : null}
      </CellContainer>
    );
  }
  return (
    // use min height to ensure enough size for all input types
    <CellContainer>
      {!editing ? (
        <Link onClick={() => setEditing(true)} base primary>
          <Trans id="empty-click-to-edit" />
        </Link>
      ) : (
        <>
          {isText ? (
            <TextInput {...commonProps} value={formValue || ''} />
          ) : (
            <StyledDropdown
              fluid
              search={tagSet && tagSet.length > 10}
              selection
              clearable
              options={tagSet}
              {...commonProps}
              disabled={annotateLoading}
            />
          )}
          <AnalyticsAwareHoverableIconButtonWithTooltip
            onClick={onSave}
            style={{ margin: `0 ${svars.spaceMedium}` }}
            help={t`save-annotation`}
            icon="check"
            gaCategory="Monitoring - search"
            gaAction="Annotate feedback"
            large="true"
            success="true"
            name="check"
            loading={annotateLoading}
            disabled={!formValue}
          />
        </>
      )}
    </CellContainer>
  );
}
EditableAnnotationCell.propTypes = {
  formId: PropTypes.string.isRequired,
  feedbackId: PropTypes.string.isRequired,
  isText: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  color: PropTypes.string,
};
EditableAnnotationCell.defaultProps = {
  isText: false,
  value: null,
  color: null,
};

export const AnnotationCellFactory = ({ isText = false }) => {
  function AnnotationCell({ formId, feedbackId, value, color }) {
    return (
      <EditableAnnotationCell
        formId={formId}
        feedbackId={feedbackId}
        isText={isText}
        value={value}
        color={color}
      />
    );
  }
  AnnotationCell.propTypes = {
    formId: PropTypes.string.isRequired,
    feedbackId: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    color: PropTypes.string,
  };
  AnnotationCell.defaultProps = { value: null, color: null };
  return AnnotationCell;
};

export const AnnotationTagCell = AnnotationCellFactory({});
export const AnnotationTextCell = AnnotationCellFactory({ isText: true });
