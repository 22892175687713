import { Button as SemanticButton } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

export const TabButton = styled(SemanticButton)`
  &&&& {
    font-weight: ${svars.fontWeightMedium};
    min-width: ${(props) => (props.fitted ? null : svars.buttonMinWidth)};
    transition: ${svars.transitionBase};
  }
`;

const blurOnKeyDown = (e) => {
  e.target.blur();
};

const makeTabButtonStyles = (
  fontSize,
  activeColor,
  baseColor = svars.fontColorBase,
  inverted = false,
  hoverTextColor = svars.colorWhite,
  backgroundColor = svars.colorWhite
) => {
  const component = styled(TabButton)`
    &&&& {
      margin: 0;
      font-size: ${fontSize};
      cursor: ${(props) => (props.disabled && 'default') || 'pointer'};
      color: ${(props) =>
        (props.active && hoverTextColor) || svars.fontColorBase};
      background-color: ${(props) =>
        (props.active && activeColor) || backgroundColor};
      border: ${svars.inputBorderWidth} solid
        ${(props) => (props.disabled && svars.colorLightGrey) || baseColor};
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;

      :hover,
      :active {
        color: ${inverted ? baseColor : hoverTextColor};
        background-color: ${(props) =>
          ((props.disabled || inverted) && backgroundColor) || activeColor};

        &&& i {
          background-color: transparent;
        }
      }

      &&& i {
        background-color: transparent;
        transition: ${svars.transitionBase};
      }

      &&& div.label {
        border-color: ${(props) =>
          (props.disabled && svars.colorLightGrey) || baseColor};
        background-color: ${(props) =>
          (props.active && activeColor) || backgroundColor};
      }
    }
  `;
  component.defaultProps = { onKeyDown: blurOnKeyDown };
  return component;
};

export const PrimaryTabButton = makeTabButtonStyles(
  svars.fontSizeBase,
  svars.accentColor,
  svars.accentColor
);

export const SecondaryTabButton = makeTabButtonStyles(
  svars.fontSizeSmall,
  svars.colorSecondary
);

export const SecondaryAccentTabButton = makeTabButtonStyles(
  svars.fontSizeBase,
  svars.accentColor,
  svars.accentColor,
  false,
  svars.colorWhite,
  svars.accentColorLighter
);

export const SecondaryDangerTabButton = makeTabButtonStyles(
  svars.fontSizeSmall,
  svars.colorDanger,
  svars.fontColorBase,
  true
);
