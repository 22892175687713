import React from 'react';

import PropTypes from 'prop-types';
import { Input, Label } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import HoverableIcon from 'components/ui/icon/HoverableIcon';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

export const TextInputStyles = css`
  background: ${svars.colorWhiteLightlyTransparent};
  outline: none;
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  border-radius: ${svars.ctaBorderRadius};
  color: ${svars.fontColorBase};
  max-width: ${({ fluid }) => (fluid ? svars.inputMaxWidth : null)};
  font-family: ${svars.fontFamilySansSerif};
  font-size: ${(props) => (props.large ? svars.fontSizeLarge : 'inherit')};
  padding: ${svars.spaceNormal};

  &:active,
  &:focus {
    border-color: ${svars.accentColor};
  }
`;

export const TextInput = styled(Input)`
  &&&& {
    visibility: ${(props) => (props.hidden ? 'hidden' : 'inherit')};
    max-width: ${({ fluid }) => (fluid ? svars.inputMaxWidth : null)};
    & input {
      ${TextInputStyles};
    }
    & div > i {
      font-size: ${(props) => (props.large ? svars.fontSizeLarge : 'inherit')};
    }
  }
`;

const IndicatorContainer = styled(Label)`
  &&& {
    display: flex;
    align-items: center;
    margin: auto 0;
    padding: ${svars.spaceNormal};
    background: ${svars.accentColorMedium};
    border-radius: ${svars.ctaBorderRadius};
    border: 1px solid ${svars.accentColorMedium};
  }
`;

export function LimitedTextInput({
  maxCharacters,
  onChange,
  style,
  ...otherProps
}) {
  const hasReachedMaxCharacters =
    maxCharacters !== undefined && otherProps.value.length >= maxCharacters;
  return (
    <TextInput
      fluid
      style={style}
      {...otherProps}
      onChange={(e, data) =>
        (maxCharacters === undefined || data.value.length <= maxCharacters) &&
        onChange(e, data)
      }
      labelPosition="right"
      label={
        <IndicatorContainer
          style={{
            fontSize: hasReachedMaxCharacters
              ? svars.fontSizeBase
              : svars.fontSizeSmaller,
            color: hasReachedMaxCharacters ? svars.colorDanger : 'inherit',
            fontWeight: hasReachedMaxCharacters
              ? svars.fontWeightBold
              : 'inherit',
          }}
        >
          {`${otherProps.value.length || 0} / ${maxCharacters}`}
        </IndicatorContainer>
      }
    />
  );
}

LimitedTextInput.propTypes = {
  style: commonPropTypes.style,
  maxCharacters: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

LimitedTextInput.defaultProps = {
  maxCharacters: undefined,
  value: '',
  style: {},
};

export function ResettableTextInput({
  onReset,
  value,
  baseIconName,
  ...otherProps
}) {
  const icon =
    value && value.length ? (
      <HoverableIcon onClick={onReset} name="delete" />
    ) : (
      baseIconName
    );
  return <TextInput {...otherProps} value={value} icon={icon} />;
}

ResettableTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  baseIconName: PropTypes.string.isRequired,
};
