import React from 'react';

import BaseCheckboxList, { baseListItemProps } from './BaseCheckboxList';
import BaseHierarchicalCheckboxList from './HierarchicalCheckboxList';

const checkBoxListAreEqual = (prevProps, nextProps) =>
  prevProps.items === nextProps.items &&
  prevProps.selectedItems === nextProps.selectedItems &&
  prevProps.searchable === nextProps.searchable;

const HierarchicalCheckboxList = React.memo(
  BaseHierarchicalCheckboxList,
  checkBoxListAreEqual
);
export { HierarchicalCheckboxList, baseListItemProps };

export default React.memo(BaseCheckboxList, checkBoxListAreEqual);
