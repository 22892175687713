import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';
import Link from 'components/ui/Link';
import { Checkbox } from 'components/ui/inputs/Checkbox';
import { TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';
import { accentInsensitiveSearch, capitalize, range } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: inherit;
  min-height: 0;
`;

const StripedListItem = styled(List.Item)`
  &&&&&& {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: ${(props) =>
      props.odd ? svars.colorWhite : svars.colorLighterGrey};
    padding: ${svars.spaceNormalLarge};

    &:hover {
      & div > label {
        color: ${svars.accentColor} !important;
      }
    }
    &::after {
      content: none;
    }
  }
`;

const CenteredMessage = styled.div`
  color: ${svars.fontColorLighter};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

/**
 * Get raw text label for search purposes.
 * This method is necessary as we receive both "text" labelled data and "label" labelled data.
 * We also allow "i18nLabel" labelled items, but it won't work with search as i18n texts are objects.
 *
 * @param {*} item
 */
const getRawLabel = (item) => item.label || item.text;

function BaseEmptyCheckboxList({ message, onReset, resetMessage }) {
  return (
    <div style={{ position: 'relative' }}>
      {range(9).map((key) => (
        <StripedListItem key={key} odd={key % 2}>
          {' '}
        </StripedListItem>
      ))}
      <CenteredMessage>
        {message ? (
          <Trans render={capitalizedTranslation} id={message} />
        ) : null}
        <Link onClick={onReset}>
          {resetMessage ? (
            <Trans render={capitalizedTranslation} id={resetMessage} />
          ) : null}
        </Link>
      </CenteredMessage>
    </div>
  );
}

BaseEmptyCheckboxList.propTypes = {
  message: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
  resetMessage: PropTypes.string,
};

BaseEmptyCheckboxList.defaultProps = { resetMessage: null };

const EmptyCheckboxList = React.memo(BaseEmptyCheckboxList);

class CheckboxList extends Component {
  constructor(props) {
    super(props);
    this.state = { textFilterValue: '', filteredItems: props.items };
    this.renderItems = this.renderItems.bind(this);
    this.onTextSearch = debounce(this.onTextSearch, 200, {
      leading: false,
    });
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    const { textFilterValue } = this.state;
    if (
      // prevProps.items &&
      items &&
      prevProps.items !== items
    ) {
      this.setState({
        filteredItems: accentInsensitiveSearch(
          items,
          textFilterValue,
          getRawLabel
        ),
      });
    }
  }

  onTextFilterChange = (e, { value }) => {
    this.setState({
      textFilterValue: value,
    });
    this.onTextSearch(value);
  };

  onTextSearch = (value) => {
    const { items } = this.props;
    this.setState({
      filteredItems: accentInsensitiveSearch(items, value, getRawLabel),
    });
  };

  onResetTextFilter = () => this.onTextFilterChange(null, { value: '' });

  onSelectAllFilteredItems = () => {
    const { selectedItems, onSelectItems } = this.props;
    const { filteredItems } = this.state;
    const toAdd = filteredItems.filter(
      ({ value }) => !selectedItems.includes(value)
    );
    onSelectItems(toAdd);
  };

  /**
   * Render empty list
   *
   * @param {*} noInputData whether there is no input data (i.e. loading is finished and data is empty)
   * @memberof CheckboxList
   */
  renderEmptyItems(noInputData) {
    const {
      loading,
      loadingDataMessage,
      emptyListMessage,
      noDataMessage,
      resetTextFilterMessage,
    } = this.props;
    return (
      <EmptyCheckboxList
        message={
          (loading && loadingDataMessage) ||
          (noInputData && noDataMessage) ||
          emptyListMessage
        }
        resetMessage={!noInputData ? resetTextFilterMessage : null}
        onReset={this.onResetTextFilter}
      />
    );
  }

  renderItems(items) {
    const {
      selectedItems,
      onSelectItem,
      onUnselectItem,
      isSelectedItem,
      nMaxSelectedItems,
      disabled,
      extraAction,
    } = this.props;
    const noMoreItems =
      nMaxSelectedItems &&
      selectedItems &&
      selectedItems.length >= nMaxSelectedItems;
    return (
      <List selection verticalAlign="middle" style={{ height: '100%' }}>
        {items.map((item, i) => {
          const isItemSelected = isSelectedItem(item, selectedItems);
          const isDisabled = disabled || (!isItemSelected && noMoreItems);
          return (
            <StripedListItem
              key={`liis-${item.key}`}
              onClick={() =>
                isItemSelected ? onUnselectItem(item) : onSelectItem(item)
              }
              disabled={isDisabled}
              odd={i % 2}
            >
              <Checkbox
                label={
                  (item.i18nLabel && (
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label>
                      <Trans
                        render={capitalizedTranslation}
                        id={item.i18nLabel}
                      />
                    </label>
                  )) ||
                  getRawLabel(item)
                }
                checked={isItemSelected}
                disabled={isDisabled}
              />
              {extraAction ? extraAction({ item }) : null}
            </StripedListItem>
          );
        })}
      </List>
    );
  }

  render() {
    const {
      items,
      selectedItems,
      isSelectedItem,
      disabled,
      searchable,
      placeholder,
      style,
      displaySelectedFirst,
      headerStyle,
      nMaxSelectedItems,
      onResetItems,
      onSelectItems,
      onUnselectAllItems,
      showNSelected,
    } = this.props;
    const { filteredItems, textFilterValue } = this.state;
    let itemCounter = '-';
    const itemCounterStyles = {
      transition: svars.transitionBase,
      fontWeight: svars.fontWeightMedium,
    };
    const noInputData = !(items && items.length);
    const sortedItems = displaySelectedFirst
      ? filteredItems.sort((a, b) => {
          if (isSelectedItem(a, selectedItems)) {
            return -1;
          }
          if (isSelectedItem(b, selectedItems)) {
            return 1;
          }
          return 0;
        })
      : filteredItems;

    if (selectedItems) {
      itemCounter = selectedItems.length;
      if (nMaxSelectedItems) {
        itemCounter = `${itemCounter} / ${nMaxSelectedItems}`;
        if (selectedItems.length >= nMaxSelectedItems) {
          itemCounterStyles.color = svars.colorDanger;
          itemCounterStyles.fontWeight = svars.fontWeightSemiBold;
        }
      }
    }
    return (
      <Container
        style={{
          ...style,
          opacity: disabled ? svars.disabledOpacity : 1,
        }}
      >
        {(showNSelected || onResetItems || onSelectItems) && (
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              ...headerStyle,
            }}
          >
            <span style={itemCounterStyles}>
              <Trans>{itemCounter} sélectionné(s)</Trans>
            </span>
            <span>
              {onSelectItems ? (
                <Link
                  base="true"
                  onClick={this.onSelectAllFilteredItems}
                  style={{ paddingRight: svars.spaceNormalLarge }}
                >
                  {capitalize(t`select-all`)}
                </Link>
              ) : null}
              {onResetItems ? (
                <Link
                  base="true"
                  disabled={!selectedItems.length}
                  onClick={onResetItems}
                  style={{ paddingLeft: svars.spaceNormal }}
                  // We deactivate this as reset can be used in situations where initial state has checked elements
                  // In these situations, the action should be available even if selectedItems is empty
                  // disabled={!selectedItems.length}
                >
                  {capitalize(t`reset`)}
                </Link>
              ) : null}
            </span>
          </span>
        )}
        {searchable && (
          <span
            style={{
              display: 'inline-flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            {onUnselectAllItems && (
              <HelpTooltip
                compact
                help={capitalize(t`clear-selection`)}
                hoverable={false}
                trigger={
                  <span>
                    <Checkbox
                      style={{ margin: `0 ${svars.spaceNormalLarge}` }}
                      indeterminate
                      onClick={selectedItems.length ? onUnselectAllItems : null}
                      disabled={!selectedItems.length}
                    />
                  </span>
                }
              />
            )}
            <TextInput
              placeholder={capitalize(placeholder)}
              icon="search"
              style={{
                width: '100%',
                transition: 'all 0.5s ease-out',
              }}
              onChange={this.onTextFilterChange}
              value={textFilterValue}
            />
          </span>
        )}

        <div
          style={{
            height: 'inherit',
            overflowY: 'auto',
            marginTop: svars.spaceNormal,
          }}
        >
          {sortedItems && sortedItems.length
            ? this.renderItems(sortedItems)
            : this.renderEmptyItems(noInputData)}
        </div>
      </Container>
    );
  }
}

export const baseListItemProps = {
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  i18nLabel: commonPropTypes.i18nText,
  // We can use object value to map multiple fields to a given value
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};

CheckboxList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...baseListItemProps,
    })
  ).isRequired,
  // Application (item, selectedItems) => <bool>
  // Item checkbox is checked if true
  isSelectedItem: PropTypes.func,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ).isRequired,
  onSelectItem: PropTypes.func.isRequired,
  // Define this method to allow multiple selection (select all)
  onSelectItems: PropTypes.func,
  onUnselectItem: PropTypes.func.isRequired,
  onResetItems: PropTypes.func,
  onUnselectAllItems: PropTypes.func,
  placeholder: PropTypes.oneOfType([
    commonPropTypes.i18nText,
    PropTypes.string,
  ]),
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  displaySelectedFirst: PropTypes.bool,
  style: commonPropTypes.style,
  showNSelected: PropTypes.bool,
  headerStyle: commonPropTypes.style,
  nMaxSelectedItems: PropTypes.number,
  emptyListMessage: commonPropTypes.i18nText,
  noDataMessage: commonPropTypes.i18nText,
  loadingDataMessage: commonPropTypes.i18nText,
  resetTextFilterMessage: commonPropTypes.i18nText,
  // Add an action at the end of each row - the function receives the row item as parameter
  extraAction: PropTypes.func,
};

CheckboxList.defaultProps = {
  showNSelected: false,
  headerStyle: {},
  disabled: false,
  loading: false,
  placeholder: '',
  searchable: false,
  isSelectedItem: (item, selectedItems) => selectedItems.includes(item.value),
  style: {},
  displaySelectedFirst: false,
  onResetItems: null,
  onUnselectAllItems: null,
  onSelectItems: null,
  nMaxSelectedItems: null,
  emptyListMessage: t`no-result`,
  loadingDataMessage: t`loading`,
  noDataMessage: t`missing-data`,
  resetTextFilterMessage: t`empty-filter`,
  extraAction: null,
};

export default CheckboxList;
