import { useCallback, useEffect, useMemo, useState } from 'react';

const useAlternativeDataSelectorPortal = (data, meta) => {
  if (!(meta.dataAlternativeOptions && meta.alternativeValueNodeId)) return {};
  const options =
    typeof meta.dataAlternativeOptions === 'function'
      ? meta.dataAlternativeOptions(data)
      : meta.dataAlternativeOptions;
  const [displayedKey, setDisplayedKey] = useState(options?.[0]?.key || null);

  const onSelectAlternative = useCallback(
    (e, target) => {
      setDisplayedKey(target.value);
    },
    [displayedKey, data, meta]
  );
  useEffect(() => {
    // Reset displayed key if data is updated and displayed key is not set by default
    // this is used when alternative option list is dynamic (form fields, ...)
    if (data && !displayedKey) {
      onSelectAlternative(null, { value: options?.[0]?.key || null });
    }
  }, [data]);
  return useMemo(() => {
    const dropdownProps = {
      options,
      onChange: onSelectAlternative,

      value: displayedKey,
      nodeId: meta.alternativeValueNodeId,
    };
    return {
      dropdownProps,
      chartProps:
        (data &&
          ((displayedKey &&
            meta.getChartProps(data, { options, value: displayedKey }, meta)) ||
            meta.getDefaultChartProps(data, { options }, meta))) ||
        null,
    };
  }, [
    options,
    onSelectAlternative,
    displayedKey,
    meta.alternativeValueNodeId,
    meta.cellKey,
    data,
  ]);
};

export default useAlternativeDataSelectorPortal;
