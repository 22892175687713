import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { getLanguageInfo } from 'reducers/locale';

import * as svars from 'assets/style/variables';

function FlagItem({ language, style }) {
  const languageInfo = getLanguageInfo(language);
  return (
    <Dropdown.Item
      style={{ margin: `${svars.spaceSmall} 0`, ...style }}
      flag={languageInfo?.flag}
      text={languageInfo?.text}
    />
  );
}

FlagItem.propTypes = {
  language: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

FlagItem.defaultProps = { style: {} };

export default FlagItem;
